<template>
	<div class="page home_page">
		<div class="w1000">
			<topHeader :isShowBack="false"></topHeader>
			<div class="block20"></div>
			<div class="topbox">
				<div class="box1" v-if="!myReadingObj">
					<div class="titlename fontsize24">温馨提示</div>
					<div class="desc fontsize20">小河狸发现你还没有词书可以进行学习哦！赶快点击下方按钮选择词书进入学习之路哦！</div>
					<div class="btnbox fontsize14">
						<div class="btn btn1" @click="handleduihuan">兑换词书</div>
						<div class="btn btn2" @click="changeStudyBook">选择词书</div>
					</div>
				</div>
				<div class="box1_ing" v-if="myReadingObj">
					<div class="leftbox">
						<div class="imgbox">
							<img class="img100" :src="myReadingObj.userBooksDTO.booksDTO.img1" />
						</div>
						<div class="btnlist">
							<div class="btn1" @click="changeStudyBook">
								<img class="icon" src="../assets/static/gh.png" />
								<div class="text fontsize12">更换词书</div>
							</div>
							<div class="btn1 btn2" @click="navigato('lookWords')">
								<img class="icon" src="../assets/static/ck.png" />
								<div class="text fontsize12">查看词表</div>
							</div>
							<div class="btn1 btn3" @click="confirmReset">
								<img class="icon" src="../assets/static/cz.png" />
								<div class="text fontsize12">重置词书</div>
							</div>
						</div>
					</div>
					<div class="rightbox">
						<div class="titlename clamp fwb">{{myReadingObj.userBooksDTO.booksDTO.title}}</div>
						<div class="progressbox"><el-progress :percentage="myReadingObj.progresstext" :stroke-width="20"
								color="#51D9A5" text-color="#723018"></el-progress></div>
						<div class="block10"></div>
						<div class="planitem">
							<div class="text1">已学 {{myReadingObj.userBooksDTO.readNum}}</div>
							<div class="text2">总词数 {{myReadingObj.userBooksDTO.booksDTO.wordNum}}</div>
						</div>
						<div class="planitem">
							<div class="text1">学习计划</div>
							<div class="text2">{{myReadingObj.userBooksDTO.perDayNum}}词/{{myReadingObj.userBooksDTO.readDay}}天</div>
						</div>
						<div class="planitem">
							<div class="text1">今日新学</div>
							<div class="text2">{{myReadingObj.readNum}}/{{myReadingObj.totalReadNum}}</div>
						</div>
						<div class="planitem">
							<div class="text1">今日复习</div>
							<div class="text2">{{myReadingObj.reviewNum}}/{{myReadingObj.totalReviewNum}}</div>
						</div>
						<div class="btnlist">
							<div class="btn1" v-if="myReadingObj.readNum<myReadingObj.totalReadNum" style="border: none;" @click="toLearnFrist()">
								<!-- <img class="icon" src="../assets/static/xx.png" /> -->
								<div class="text fontsize14">去学习</div>
							</div>
							<div class="btn1" v-if="myReadingObj.readNum>=myReadingObj.totalReadNum" style="border: none;" @click="toLearnMore()">
								<!-- <img class="icon" src="../assets/static/xx.png" /> -->
								<div class="text fontsize14">去学习</div>
							</div>
							<div class="btn1 btn2" style="border: none;" @click="toLearnSecond()">
								<!-- <img class="icon" src="../assets/static/fx.png" /> -->
								<div class="text fontsize14">去复习</div>
							</div>
							<div class="btn1 btn3" style="border: none;" @click="toLearnMore()">
								<!-- <img class="icon" src="../assets/static/lxx.png" /> -->
								<div class="text fontsize14">加量学习</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box2">
					<div class="box2_top">
						<div class="userinfo">
							<div class="avatar"><img class="img100" :src="userInfo.hlImage || require('../assets/logo.png')" />
							</div>
							<div class="rigthcon">
								<div class="username clamp">{{userInfo.nikename || "匿名"}}</div>
								<div class="desc">
									<div class="text1" v-if="userInfo.stageId">{{getclassLevel(userInfo.stageId)}}</div>
									<div class="text2">{{vipTpyes[userInfo.level]}}</div>
								</div>
							</div>
							<div class="btnbox"><el-button size="small" @click="outLogin">退出登录</el-button></div>
						</div>
						<div class="typebox">
							<div class="line"></div>
							<div class="text fontsize16">学习统计</div>
						</div>
						<div class="tongjibox">
							<div class="item">
								<div class="text1 fontsize20">{{tongjiobj.bookNum}}</div>
								<div class="text2 fontsize14">词书</div>
							</div>
							<div class="item">
								<div class="text1 fontsize20">{{tongjiobj.studyWordNum}}</div>
								<div class="text2 fontsize14">已学单词数</div>
							</div>
							<div class="item">
								<div class="text1 fontsize20">{{tongjiobj.studyDay}}</div>
								<div class="text2 fontsize14">加入学习天数</div>
							</div>
						</div>
					</div>
					<div class="callmebox">
						<!-- <div class="textbox">
							<img class="icon" src="../assets/static/qq.png" />
							<div class="text1 fontsize20">QQ客服</div>
							<div class="text2 fontsize20">xxxxxx</div>
						</div> -->
						<div class="textbox">
							<!-- <img class="icon" src="../assets/static/wechat.png" />
							<div class="text1 fontsize20">微信服务号</div> -->
							<el-popover
							  placement="top"
							  trigger="hover">
							  <div style="margin: 0 auto; width: 547px;height: 200px;border-radius: 8px;"><img src="../assets/static/gzh.png" alt="在线客服" class="img100"></div>
							  <div slot="reference" class="pointer">
								  <!-- <div class="text2 fontsize20 pointer">河狸爱学</div> -->
								  <img src="../assets/static/gzh.png" alt="在线客服" class="gzhicon"><img />
							  </div>
							</el-popover>
						</div>
					</div>
				</div>
			</div>
			<div class="block20"></div>
			<!-- 学习详情 -->
			<div class="centerbox">
				<div class="itemobj"><img class="img100" src="../assets/static/xxxq.png"
						@click="navigato('learnDetail')" /></div>
				<div class="itemobj"><img class="img100" src="../assets/static/dcb.png"
						@click="navigato('wordBooks')" /></div>
				<div class="itemobj"><img class="img100" src="../assets/static/xcb.png"
						@click="navigato('cancelWords')" /></div>
				<div class="itemobj"><img class="img100" src="../assets/static/ccb.png"
						@click="navigato('wrongWords')" /></div>
			</div>
			<div class="block40"></div>
			<div class="bottombox">
				<div class="leftbox">
					<div class="typebox">
						<div class="textbox fontsize20" :class="{'active':checkedBtnsindex==0}" @click="updateCheckedBtns(0)">
							<div class="t1">日历</div>
							<div class="line"></div>
						</div>
						<div class="textbox fontsize20" :class="{'active':checkedBtnsindex==1}" @click="updateCheckedBtns(1)">
							<div class="t1">单词量</div>
							<div class="line"></div>
						</div>
						<div class="textbox fontsize20" :class="{'active':checkedBtnsindex==2}" @click="updateCheckedBtns(2)">
							<div class="t1">学习时长</div>
							<div class="line"></div>
						</div>
					</div>
					<div class="calendar-block" v-if="checkedBtnsindex==0">
						<!-- <img src="../assets/static/calendar_1.png" class="set-img1"></img> -->
						<img src="../assets/static/calendar_3.png" class="set-img3"></img>
						<img src="../assets/static/calendar_4.png" class="set-img4"></img>
						<img src="../assets/static/calendar_5.png" class="set-img5"></img>
						<div class="set-icon">
							<img src="../assets/static/calendar_icon.png" class="icon"></img>
							<div class="hide_dateinput">
								<el-date-picker
								    @change="dateChange"
								    v-model="curdate.date"
								    type="date"
								    value-format="yyyy-MM-dd">
								  </el-date-picker>
							</div>
						</div>
						<div class="week">
							<div class="week-item" v-for="item,index in weekLabels" :key="index">
								<span class="week-label">{{item}}</span>
								<span class="week-num pointer" @click="dateChange(weekNum[index].date)"
									:class="{'week-ckecked': weekNum[index].day === weekChecked}">{{weekNum[index].day}}</span>
							</div>
						</div>
						<div class="ddatebox">
							<div class="hide_dateinput">
								<div class="month">{{curMonth}}</div>
								<div class="year">{{curYear}}</div>
								<el-date-picker
								    @change="dateChange"
								    v-model="curdate.date"
								    type="date"
								    value-format="yyyy-MM-dd">
								  </el-date-picker>
							</div>
						</div>
						<div class="calendar-text">
							<div class="line1">{{curdate.month}}月{{curdate.day}}日的你，</div>
							<div class="line2" v-if="tongjiByDate">背了<span class="calendar-text-num">{{tongjiByDate.readNum || 0}}</span> 个单词，复习了<span
									class="calendar-text-num">{{tongjiByDate.reviewNum || 0}}</span>个单词 </div>
						</div>
					</div>
					<!-- 测试报告 -->
					<div class="echarts_box" v-show="checkedBtnsindex==1 || checkedBtnsindex==2">
						<div class="topbox">
							<div class="colorbox">
								<div class="item" v-if="checkedBtnsindex==1">
									<div class="text1" style="background-color: #FE8C22;"></div>
									<div class="text2">学习量</div>
								</div>
								<div class="item" v-if="checkedBtnsindex==1">
									<div class="text1" style="background-color: #1FC287;"></div>
									<div class="text2">复习量</div>
								</div>
							</div>
							<div class="datebox">
								<div class="left" @click="echartsDate('last')"><img class="icon" src="../assets/static/leftgo_white.png"></img></div>
								<div class="date1" v-if="categoryData.length>0">
									{{categoryData[0]}}
									<div class="line">-</div>
									{{categoryData[categoryData.length-1]}}
								</div>
								<div class="right" @click="echartsDate('next')"><img class="icon" src="../assets/static/leftgo_white.png"></img></div>
							</div>
						</div>
						<div class="echartsdiv" id="echartsdiv"></div>
					</div>
				</div>
				<div class="rightbox">
					<div class="typebox">
						<div class="line"></div>
						<div class="text fontsize20">我的荣誉</div>
						<div class="text1 fontsize14">筑坝赢荣誉</div>
					</div>
					<div class="hoverlist">
						<div class="item" :class="{'active':myPointSum.branchNum>=20}">
							<img src="../assets/game/s20.png" class="icon"></img>
							<div class="text" >{{myPointSum.branchNum>=20?"已点亮":"未点亮"}}</div>
						</div>
						<div class="item" :class="{'active':myPointSum.signNum>=10}">
							<img src="../assets/game/q10.png" class="icon"></img>
							<div class="text" >{{myPointSum.signNum>=10?"已点亮":"未点亮"}}</div>
						</div>
						<div class="item" :class="{'active':myPointSum.woodenTop>=10}">
							<img src="../assets/game/h10.png" class="icon"></img>
							<div class="text" >{{myPointSum.woodenTop>=10?"已点亮":"未点亮"}}</div>
						</div>
						<div class="item" :class="{'active':myPointSum.branchNum>=50}">
							<img src="../assets/game/s50.png" class="icon"></img>
							<div class="text" >{{myPointSum.branchNum>=50?"已点亮":"未点亮"}}</div>
						</div>
						<div class="item" :class="{'active':myPointSum.signNum>=30}">
							<img src="../assets/game/q30.png" class="icon"></img>
							<div class="text" >{{myPointSum.signNum>=30?"已点亮":"未点亮"}}</div>
						</div>
						<div class="item" :class="{'active':myPointSum.woodenTop>=30}">
							<img src="../assets/game/h30.png" class="icon"></img>
							<div class="text" >{{myPointSum.woodenTop>=30?"已点亮":"未点亮"}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="block30"></div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import registerForm from "../components/registerForm/registerForm.vue";
	import topHeader from "@/components/topHeader/topHeader.vue";
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
			registerForm,
		},
		data() {
			return {
				checkedBtnsindex:0,
				classLevel:[],//阶段
				weekLabels: [],
				weekNum: [],
				weekChecked: 0, //当天
				curYear: "", //d当年
				curMonth: "", //d当月
				curdate:{},//当前日期year month day
				isShowRest: false, // 是否显示重置词书
				isShowStudyPlan:false,//学习计划
				selobj:{
					worknum:5,
					workday:10,
				},
				checkedBtns: [{
						label: '日历',
						checked: true
					},
					// {
					// 	label: '单词量',
					// 	checked: false
					// },
					// {
					// 	label: '学习时长',
					// 	checked: false
					// }
				],
				checkedBtnsindex:0,//下标
				//myReadingObj:null,//当前学习词书
				isShowInput:false,//兑换会员
				tongjiobj:{
					bookNum: 0,
					studyDay: 0,
					studyWordNum: 0,
				},
				tjwordobj:{
					endDate: "",
					startDate: "",
				},//单词量统计
				categoryData: [],//日期数据列表
				xuexiDate: [],
				fuxiData: [],
				studytimeData: [],//学习时间 分钟
				tjtimeobj:{
					endDate: "",
					startDate: "",
				},//学习时长统计
				barmax:30,//y轴最大值
				timebarmax:20,//y轴最大值
				tongjiByDate:{
					readNum: 0,
					reviewNum: 0,
					studyTime: 0
				},//根据日期的统计数据
				myPointSum: {
					branchNum: 0,
					signNum: 0,
					woodenNum: 0,
					woodenTop: 0
				}, //数量
				myChart:null,
			};
		},
		mounted: function() {
			if(this.isLogin){
				//初始化
				this.initPage();
			}else{
				location.href = "/login"
			}
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'vipTpyes']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO", "SET_ISLOGIN"]),
			navigato(name) {
				this.$router.push({
					name,
				});
			},
			//初始化
			async initPage() {
				var _this = this
				//轮播图和广告
				//await this.getwheelPage(1)
				//首页学习计划
				this.getMyReading()
				//阶段
				this.getbooksStage()
				//统计
				this.userTotal()
				//获取周日到周六的日期
				this.getSundayToSaturdayDates(new Date());
				// 获取七天前的日期
				this.tjwordobj.startDate = this.$util.formatdate(this.subtractDays(7,new Date()))
				this.tjwordobj.endDate = this.$util.formatdate(new Date())
				//统计单词
				this.tongjiword()
				//我的荣誉
				this.getmyPointSum()
				setTimeout(() => {
				    _this.$nextTick(() => {
				    	var echarts = _this.$echarts
				    	var chartDom = document.getElementById('echartsdiv');
						// /console.log(chartDom)
				    	_this.myChart = echarts.init(chartDom);
				    });
				}, 800)
				
			},
			//图表生成
			updateChars() {
				var _this = this
				var option = {}
				if(this.myChart){
					this.myChart.clear();
				}
				
				if(this.checkedBtnsindex==1){
					option = {
						tooltip: {
						    trigger: 'axis',//axis
							axisPointer: {
							    type: 'cross',
							    crossStyle: {
							       color: '#999'
							    }
							}
						},
					    xAxis: {
					    	type: 'category',
					    	data: this.categoryData,
					    	axisLine:{
					    		lineStyle: {
					    		    color: '#FE8C22' // 改变为蓝色
					    		}
					    	},//去掉y轴 #FED8A7
					    	axisLabel: {
					    	    color: '#FE8C22',
					    	    interval: 0, // 横轴信息全部显示
					    	    //rotate: -30, // -30度角倾斜显示
					    	},
					    },
					    yAxis: {
					    	type: 'value',
					    	max:this.barmax,
					    	axisLine:{
					    		show:true,
					    		lineStyle: {
					    		    color: '#FE8C22' // 改变为蓝色
					    		}
					    	},//去掉y轴
					    	axisTick:{ 
					    		show:true,
					    	},
					    	splitLine: {
					    	    show: true,
					    	    lineStyle: {
					    	        // 设置分割线为虚线
					    	        type: 'dashed',
					    	        color: '#FE8C22' // 设置虚线的颜色
					    	    }
					    	}
					    },
					    grid: { x: 28, y: 18, x2: 3, y2: 20 },//调整此处大小即可控制空白 y是上下
					    series: [
					    	{
					    		name:"学习量",
					            data: this.xuexiDate, // 学习量
					            type: 'bar',
					    		itemStyle: {
					    		    color: '#FE8C22' // 这里设置系列1的颜色为红色
					    		}
					        }, 
					    	{
					    		name:"复习量",
					    		data: this.fuxiData, //复习
					    		type: 'bar',
					    		itemStyle: {
					    		    color: '#1FC287' // 这里设置系列1的颜色为红色
					    		}
					    	}
					    ]
					};
				}else{
					//console.log(this.studytimeData)
					option = {
						tooltip: {
						    trigger: 'axis',//axis
							axisPointer: {
							    type: 'cross',
							    crossStyle: {
							       color: '#999'
							    }
							}
						},
					    xAxis: {
					    	type: 'category',
					    	data: this.categoryData,
							axisLine:{
								lineStyle: {
								    color: '#FE8C22' // 改变为蓝色
								}
							},//去掉y轴 #FED8A7
							axisLabel: {
							    color: '#FE8C22',
							    interval: 0, // 横轴信息全部显示
							    //rotate: -30, // -30度角倾斜显示
							},
					    },
					    yAxis: {
					    	type: 'value',
							max:this.timebarmax,
					    	axisLine:{
								show:true,
								lineStyle: {
								    color: '#FE8C22' // 改变为蓝色
								}
							},//去掉y轴
					    	axisTick:{ 
					    		show:true,
					    	},
							splitLine: {
							    show: true,
							    lineStyle: {
							        // 设置分割线为虚线
							        type: 'dashed',
							        color: '#FE8C22' // 设置虚线的颜色
							    }
							}
					    },
					    grid: { x: 28, y: 18, x2: 3, y2: 20 },//调整此处大小即可控制空白 y是上下
					    series: [{
							name: '学习分钟',
					        type: 'line', // 图表类型，这里是折线图
					        data: this.studytimeData, // 数据
					        lineStyle: {
					            color: '#FE8C22' // 折线颜色
					        },
					        itemStyle: {
					            color: '#FE8C22' // 数据点颜色
					        },
							areaStyle: {
							    color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [  // 渐变颜色
									    {
									      offset: 0,
									      color: 'rgba(242, 130, 0, 0.50)',
									    },
									    {
									      offset: 1,
									      color: 'rgba(242, 130, 0, 0.00)',
									    },
									],
									global: false,
							    },
							},
					    }]    
					};
				}
				//生成
				this.myChart.setOption(option);
			},
			// 选中日历这栏的按钮
			updateCheckedBtns(index) {
				this.checkedBtnsindex = index
				if(this.checkedBtnsindex==1){
					//图表生成
					this.updateChars()
				}
				if(this.checkedBtnsindex==2){
					this.updateChars()
				}
			},
			//我的荣誉
			getmyPointSum(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/game/userGamePoint/myPointSum", params).then(function(res) {		
					if (res.code === 200) {
						_this.myPointSum = res.data
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//选择日期
			dateChange(e){
				//console.log(e)
				//根据日期查看时间
				if(e){
					const cur = this.$util.getDateTime(e)
					const startTime = this.$util.getDateTime(this.weekNum[0].date) // 开始时间
					const endTime = this.$util.getDateTime(this.weekNum[this.weekNum.length-1].date) // 结束时间
					const dateToCheckMs = cur.getTime();
					const startTimeMs = startTime.getTime();
					const endTimeMs = endTime.getTime();
					if(dateToCheckMs >= startTimeMs && dateToCheckMs <= endTimeMs){
						//处于七天内
						this.getCalendar(e)
					}else{
						console.log("out time")
						this.getSundayToSaturdayDates(cur)
					}
				}
			},
			//根据日期查看时间
			getCalendar(seldate){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					date:seldate
				}
				showLoading()
				_this.$http.post("app/word/userReading/getCalendar", params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						if(res.data){
							_this.tongjiByDate = res.data
							_this.curdate = _this.$util.getMonthDay(_this.$util.getDateTime(seldate))
							_this.weekChecked = _this.curdate.day
						}else{
							_this.tongjiByDate = {
								readNum: 0,
								reviewNum: 0,
								studyTime: 0
							}//根据日期的统计数据
							_this.curdate = _this.$util.getMonthDay(_this.$util.getDateTime(seldate))
							_this.weekChecked = _this.curdate.day
							//_this.$message.info("暂无相关数据！")
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//点击日期选择统计
			echartsDate(type){
				if(type=="last"){
					this.tjwordobj.endDate = this.tjwordobj.startDate
					this.tjwordobj.startDate = this.$util.formatdate(this.subtractDays(7,this.$util.getDateTime(this.tjwordobj.startDate)))
				}else{
					this.tjwordobj.startDate = this.tjwordobj.endDate
					this.tjwordobj.endDate = this.$util.formatdate(this.addtheDays(7,this.$util.getDateTime(this.tjwordobj.endDate)))
				}
				//统计单词
				this.tongjiword()
			},
			//统计单词
			tongjiword(){
				var _this = this
				var params = this.tjwordobj
				params["userUuid"] = this.userInfo.uuid
				showLoading()
				_this.$http.post("app/word/userReading/getCalendarList", params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						var list = res.data
						_this.categoryData = []
						_this.xuexiDate = []
						_this.fuxiData = []
						_this.studytimeData = []
						var barmax = _this.barmax
						var timebarmax = 0
						list.reverse().forEach((item, index) => {
							_this.categoryData.push(_this.$util.formatdatedian(item.createDate))
							_this.xuexiDate.push(item.readNum)
							_this.fuxiData.push(item.reviewNum)
							_this.studytimeData.push(item.studyTime)
							if(item.readNum>=item.reviewNum){
								if(item.readNum>barmax){
									barmax = item.readNum +20
								}
							}else{
								if(item.reviewNum>=barmax){
									barmax = item.reviewNum +20
								}
							}
							if(item.studyTime>timebarmax){
								timebarmax = item.studyTime
							}
						});
						_this.barmax = barmax
						_this.timebarmax = timebarmax + 10
						if(_this.checkedBtnsindex==1 || _this.checkedBtnsindex==2){
							_this.updateChars()
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//去学习
			toLearnFrist(){
				var _this = this
				if(this.myReadingObj.userBooksDTO.readNum>=this.myReadingObj.userBooksDTO.booksDTO.wordNum){
					this.$message.info("此词书已学完！请切换其他词书！")
					return false;
				}
				if(this.userInfo.level==0){
					if(this.myReadingObj.userBooksDTO.readNum>=10){
						_this.$confirm("体验学习已经完成，开通会员畅享学习！", '提示', {
							confirmButtonText: '去开通',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$router.push({
								name:"vipPage",
							});
						}).catch(() => {});
						return false;
					}
				}
				this.navigato('learnFrist')
			},
			//加量学习
			toLearnMore(){
				var _this = this
				if(this.userInfo.level==0){
					if(this.myReadingObj.userBooksDTO.readNum>=10){
						_this.$confirm("体验学习已经完成，开通会员畅享学习！", '提示', {
							confirmButtonText: '去开通',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$router.push({
								name:"vipPage",
							});
						}).catch(() => {});
						return false;
					}
				}
				if(this.myReadingObj.userBooksDTO.readNum>=this.myReadingObj.userBooksDTO.booksDTO.wordNum){
					this.$message.info("此词书已学完！请切换其他词书！")
					return false;
				}
				if(this.myReadingObj.readNum >=this.myReadingObj.totalReadNum){
					_this.$confirm("今天新学已完成，确定要加量学习？", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						var myReadingObj = _this.myReadingObj
						let readNum = myReadingObj.readNum
						let perDayNum = myReadingObj.perDayNum
						// 计算A是B的多少倍，并向上取整
						let multiple = Math.ceil(readNum / perDayNum);
						// 如果向上取整后的倍数乘以B仍然小于A，则继续增加倍数
						while (multiple * perDayNum <= readNum) {
						    multiple++;
						}
						//console.log(multiple)
						myReadingObj.perDayNum = multiple * perDayNum;
						if(myReadingObj.perDayNum == myReadingObj.totalReadNum){
							myReadingObj.perDayNum = myReadingObj.totalReadNum + perDayNum;
						}
						console.log(myReadingObj.perDayNum)
						_this.SET_Data({ key: 'myReadingObj', value:myReadingObj})
						this.navigato('learnFrist')
					}).catch(() => {});
				}else{
					this.$message.error("请先学习完今天的计划任务，再加量学习！")
				}
			},
			//去复习
			toLearnSecond() {
				if(this.myReadingObj.reviewNum >this.myReadingObj.totalReviewNum){
					this.$message.error("今天复习已完成！")
				}else{
					this.navigato('needLearn')
				}
			},
			// 确认重置词书
			confirmReset() {
				var _this = this
				_this.$confirm("确定要重置此词书吗？重置后此词书已学记录将会被清空！", '重置图书', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var params = {
						bookId: _this.myReadingObj.bookId,
						userUuid: _this.userInfo.uuid
					}
					_this.$http.post('app/word/userBooks/resetMyBooks', params).then(function(res) {	
						if (res.code === 200) {
							_this.$message.success("重置词书成功！")
							//首页学习计划
							_this.getMyReading()
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				}).catch(() => {});
				
				
			},
			// 变更词书和选择词书
			changeStudyBook() {
				this.navigato("allOrMyBooks")
			},
			//兑换会员
			handleduihuan(){
				var _this = this
				this.$prompt('请输入兑换码', '兑换会员', {
				        confirmButtonText: '确定兑换',
				        cancelButtonText: '取消',
				        inputPlaceholder:"请输入"
				    }).then(({ value }) => {
						if(value){
							var params = {
								code: value.trim(),
								userUuid: _this.userInfo.uuid
							}
							_this.$http.post('app/word/booksGoods/code/geUserVipByCode', params).then(function(res) {
								//数据处理
								if (res.code == 200) {
									//刷新用户信息
									_this.refreshUser()
									_this.$alert('兑换成功！', '提示', {
									    confirmButtonText: '确定',
									    callback: action => {}
									});
								} else {
									_this.$confirm(res.message, '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {}).catch(() => {});
								}
							})
						}else{
							_this.$message({
								type: 'error',
								message: '请输入兑换码 '
							});
						}
						
				    }).catch(() => {
				        this.$message({
				            type: 'info',
				            message: '取消输入'
				        });       
				});
			},
			//刷新用户信息
			refreshUser(){
				var _this = this
				var params = {
					id: this.userInfo.id
				}
				this.$http.post('app/hlexam/hlUser/detail', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.SET_Data({ key: 'userInfo', value:res.data})
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//统计
			userTotal(){
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid
				}
				this.$http.post('app/word/userReading/userTotal', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.tongjiobj = res.data
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//阶段
			getbooksStage() {
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 88
				}
				this.$http.post('app/word/booksStage/index', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						// records.forEach((item, index) => {
							
						// });
						_this.SET_Data({ key: 'classLevel', value:records})
						_this.classLevel = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//阶段
			getclassLevel(stageId){
				const obj = this.classLevel.find((ifitem) => { //这里的options就是上面遍历的数据源
					return ifitem.id == stageId //筛选出匹配数据
				});
				if(obj){
					return obj.title;
				}else{
					return stageId
				}
			},
			// 从当前日期减去指定的天数
			subtractDays(days,date) {
				date.setDate(date.getDate() - days);
				return date;
			},
			addtheDays(days,date) {
				date.setDate(date.getDate() + days);
				return date;
			},
			//获取周日到周六的日期
			getSundayToSaturdayDates(now) {
				this.weekChecked = now.getDate();
				this.curdate = this.$util.getMonthDay(now)
				this.getCalendar(this.curdate.date)
				// console.log(now.getMonth())
				// console.log(now.getFullYear())
				this.curMonth = this.getCurrentMonthInEnglish(now);
				this.curYear = now.getFullYear();
				const dayOfWeek = now.getDay(); // 0代表周日
				// 找到本周日的日期
				// let sunday = new Date(
				// 	now.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? 6 : 0))
				// );
				// 找本周一
				var day = now.getDay() || 7;
				let sunday = new Date(now.getFullYear(),now.getMonth(),now.getDate() + 1 - day);
				// 创建一个数组来保存周日到周六的日期天数
				const weekDays = [];
				// 从周日开始，添加七天的日期到数组中
				for (let i = 0; i < 7; i++) {
					const year = sunday.getFullYear();
					const month = sunday.getMonth() + 1; //当前月
					const day = sunday.getDate()
					weekDays.push({
						day:day,
						year:year,
						month:month,
						date:[year, month, day].map(this.formatNumber).join('-')
					}); // 只获取日期的天数部分
					sunday.setDate(sunday.getDate() + 1); // 将日期增加一天以获取下一天的日期
				}
				//console.log(weekDays)
				this.weekNum = weekDays;
				this.weekLabels = ['M', 'T', 'W', 'T', 'F', 'S','S']
			},
			formatNumber(n) {
				n = n.toString()
				return n[1] ? n : '0' + n
			},
			getCurrentMonthInEnglish(now) {
				const months = [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December",
				];
				const currentMonthIndex = now.getMonth(); // 0-11
				return months[currentMonthIndex];
			},
			//首页学习计划
			getMyReading(){
				var _this = this
				//需要登录
				var params = {
					userUuid:this.userInfo.uuid
				}
				this.$http.post('app/word/userReading/getMyReading', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data){
							//进度
							if(res.data.userBooksDTO.readNum!=0){
								res.data["progresstext"] = Number(((res.data.userBooksDTO.readNum / res.data.userBooksDTO.booksDTO.wordNum)  * 100).toFixed(0));
							}else{
								res.data["progresstext"] = 0
							}
							if(res.data.userBooksDTO.readNum>0){
								res.data.readStatus = 1
							}
							if(res.data.userBooksDTO.readNum>=res.data.userBooksDTO.booksDTO.wordNum){
								res.data.readStatus = 2
							}
							res.data["perDayNum"] = res.data.userBooksDTO.perDayNum
							//_this.myReadingObj = res.data
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//退出
			outLogin() {
				var _this = this;
				this.$confirm("确认要退出登录嘛？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						_this.$http.post("app/hlexam/login/logout", {}).then(function(res) {
							//数据处理
							if (res.code == 200) {
								_this.$message.success("退出登录成功！");
								sessionStorage.setItem("token", "");
								_this.SET_ISLOGIN(false);
								_this.SET_USERINFO({});
								//保存
								sessionStorage.setItem("key_state", "");
								location.href = "/login"
							} else {
								_this
									.$confirm(res.message, "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning",
									})
									.then(() => {})
									.catch(() => {});
							}
						});
					})
					.catch(() => {});
			},
			//跳转
			handleSearche(url) {
				if (url) {
					if (url.indexOf("http://") > -1 || url.indexOf("https://") > -1) {
						this.$util.windowOpen(url);
					} else {
						const newUrl = url.split("?")[0];
						const params = this.parseString(url);
						this.$router.push({
							path: "/" + newUrl,
							query: params,
						});
					}
				} else {
					console.log("无跳转路径");
					this.$message.info("此模块暂未开放");
				}
			},
			//处理参数
			parseString(url) {
				const obj = {};
				if (url && url.split("?").length > 1) {
					const params = url.split("?")[1].split("&");
					params.map((item) => (obj[item.split("=")[0]] = item.split("=")[1]));
				}
				console.log(obj);
				return obj;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.echarts_box{
		width: 100%;
		.colorbox{
			width: 100%;
			display: flex;
			align-items: center;
			.item{
				display: flex;
				align-items: center;
				margin: 0 18px;
				.text1{
					width: 18px;
					height: 18px;
					border-radius: 38px;
				}
				.text2{
					font-style: 16px;
					margin-left: 10px;
				}
			}
		}
		.topbox{
			width: 100%;
			padding: 12px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.text1{
				color: #222;
				font-size: 13px;
			}
			.datebox{
				width: 158px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border:1px solid #FE8C22;
				background-color: #FE8C22;
				border-radius: 58px;
				padding: 6px 8px;
				color: #ffffff;
				.date1{
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
					.line{
						padding: 0 5px;
					}
				}
				.left{
					width: 28px;
					display: flex;
					align-items: center;
					cursor: pointer;
					.icon{
						width: 15px;
						height: 15px;
					}
				}
				.right{
					width: 28px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					cursor: pointer;
					.icon{
						width: 15px;
						height: 15px;
						transform: rotate(180deg);
					}
				}
			}
		}
		.echartsdiv{
			width: 488px;
			height: 388px;
		}
	}
</style>